/*
 *   File : secondary.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Secondary Button
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 30-08-2022
 */

/*import style*/
import styles from '../../styles/components/secondary.module.scss';

const SecondaryBtn = (props) => {
	return (
		<button id={props.id}
			className={props.disabled ? `e-input-wrap ${styles.e_s_btn} ${styles.e_disabled} ${props.className}` : `e-input-wrap ${styles.e_s_btn} ${props.className}`}
			data-bs-dismiss={props.cancel}
			data-bs-toggle={props.toggle}
			data-bs-target={props.target}
			onClick={props.handleClick}
			type={props.type ? props.type : "submit"}
		><span>{props.name}</span></button>
	);
}

export default SecondaryBtn;