/*
 *   File : toast.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : toast
 *   Integrations :
 *   Version : v1.0
 *  Created : 02-09-2022
 */

import React from 'react';

/*import style*/
import styles from '../../styles/components/toast.module.scss';

function Toast(props) {
	return (
		<>
			<span
				className={
					props.type === "success" ?
						styles.e_success
						: styles.e_fail
				}>
				{props.data}
			</span>
		</>
	);
}
export default Toast;
