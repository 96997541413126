/*
 *   File : fileUpload.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : upoad
 *   Integrations :
 *   Version : 1.0
 *   Created : 29-08-2022
 */

/*import packages*/
import React, { useState } from 'react';
import $ from 'jquery';
import { FileUploader } from "react-drag-drop-files";

/*import Services */

const FileUpload = (props) => {

  $('input[type="file"]').click(function (e) {
    e.target.value = null
  });



  return (

    <input
      multiple={props.multiple}
      id={props.id}
      className={props.className}
      type="file"
      key={props.key}
      accept={props.accept}
      onChange={(e) => props.onChange}


      {...props}
    />
  );
}

export default FileUpload;
