/*
 *   File : detail.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Details
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 25-01-2022
 */

/* import packages */
import React, { useState, useRef, useEffect } from 'react';

/* import components */


/*Style Import*/
import styles from '../../styles/components/accordion.module.scss';

/* import assets */
import Tooltip from '../../assets/images/dataFormatting/tooltip.svg';
import One from '../../assets/images/dataFormatting/one.svg';
import Two from '../../assets/images/dataFormatting/two.svg';
import Three from '../../assets/images/dataFormatting/three.svg';
import Four from '../../assets/images/dataFormatting/four.svg';
import Five from '../../assets/images/dataFormatting/five.svg';
import Console from '../../assets/images/dataFormatting/console.png';
import DownloadConsole from '../../assets/images/new-design/trade-book-1.png';
import MailConsole from '../../assets/images/new-design/trade-book-2.png';
import LastImage from '../../assets/images/new-design/trade-book-3.png';


const Accordion = () => {

  return (
    <>
      <div class="accordion" id="accordionExample">
        <div class="accordion-item">
          <h2 class="accordion-header d-flex" id="headingOne">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              {/* <img src={Tooltip} className="me-3" /> */}
              How do I get my tradebook upload?
            </button>
          </h2>
          <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <div className='e-item-wrapper mb-3'>
                <div className='row'>
                  <div className='col-lg-1 gx-0'>
                    <div className="e-number">1</div>
                  </div>
                  <div className='col-lg-11 col-10'>
                    <p>Click here to go to</p>
                    <a href="https://console.zerodha.com/reports/tradebook">https://console.zerodha.com/reports/tradebook</a>
                  </div>
                </div>
              </div>
              <div className='e-item-wrapper mb-3'>
                <div className='row'>
                  <div className='col-lg-1 gx-0'>
                    <div className="e-number">2</div>
                  </div>
                  <div className='col-lg-11 col-10'>
                    <p>Go to <span>Reports > Downloads</span></p>

                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='col-lg-11 offset-1'>
                    <img src={Console} style={{ width: "100%" }} />
                  </div>
                </div>
              </div>
              <div className='e-item-wrapper mb-3'>
                <div className='row'>
                  <div className='col-lg-1 gx-0'>
                    <div className="e-number">3</div>
                  </div>
                  <div className='col-lg-11 col-10'>
                    <p>Follow futher steps in download page:</p>
                    <ul>
                      <li>- In segment select <span>Equity</span></li>
                      <li>- Select one date range for <span></span>“one finacial range”</li>
                      <li>- Click the “arrow”</li>
                      <li> - Repeat this steps to get data you need for all financial year</li>
                    </ul>

                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='col-lg-11 offset-1'>
                    <img src={DownloadConsole} style={{ width: "100%" }} />
                  </div>
                </div>
              </div>
              <div className='e-item-wrapper mb-3'>
                <div className='row'>
                  <div className='col-lg-1 gx-0'>
                    <div className="e-number">4</div>
                  </div>
                  <div className='col-lg-11'>
                    <p>After selecting date range Download CSV file</p>
                    {/* <ul>
                      <li>- After requesting the report, go to your email.</li>
                      <li>- For each year-wise report, you will receive an email with a download link.</li>
                      <li>- Download these reports and go back.</li>
                    </ul> */}

                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='col-lg-11 offset-1'>
                    <img src={MailConsole} style={{ width: "100%" }} />
                  </div>
                </div>
              </div>
              <div className='e-item-wrapper'>
                <div className='row'>
                  <div className='col-lg-1 gx-0'>
                    <div className="e-number">5</div>
                  </div>
                  <div className='col-lg-11 col-10'>
                    <p>Come back to Mintd & upload your files</p>                   
                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='col-lg-11 offset-1'>
                    <img src={LastImage} style={{ width: "100%" }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </>
  );
}


export default Accordion;