/*
 *   File : primary.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Primary Button
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 30-08-2022
 */

/*import style*/
import { useEffect } from 'react';
import styles from '../../styles/components/primary.module.scss';


const PrimaryBtn = (props) =>{
  // useEffect(()=>{

  // },[])
  return (
		<button id={props.id}
			className={props.disabled ? `e-input-wrap ${styles.e_p_btn} ${styles.e_disabled} ${props.className}` : `e-input-wrap ${styles.e_p_btn} ${props.className}`}
			data-bs-dismiss={props.cancel} 
			data-bs-toggle={props.toggle} 
			data-bs-target={props.target} 
			onClick={props.handleClick} 
			disabled={props.disable}
			type={props.type ? props.type : "submit"}
		>	
			<img src={props.image}/>
			<span>{props.name}</span>
		</button>
  );
}

export default PrimaryBtn;