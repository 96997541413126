import { useState, useEffect } from 'react';

import { PrimaryBtn, SecondaryBtn } from '../../components/cta';
import { Register } from '../../components/modal';

//assets 
import Logo from '../../assets/images/new-design/logo.svg';
import Nifty50 from '../../assets/images/new-design/nifty-50.svg';
import Zerodha from '../../assets/images/new-design/zer-legend.svg'
import MintdLeg from '../../assets/images/new-design/mint-legend.svg';
import Alert from '../../assets/images/new-design/alert-triangle.svg';
import Arrow from '../../assets/images/new-design/Vector-arrow.svg';

import Equity from '../../assets/images/new-design/Equity.svg';
import Bond from '../../assets/images/new-design/Bond.svg';
import RealEstate from '../../assets/images/new-design/RealEstate.svg';
import Commodity from '../../assets/images/new-design/Commodity.svg';
import LineGraph from '../../assets/images/new-design/line-graph.svg';
import CurvedGraph from '../../assets/images/new-design/curved-graph.svg';
import JoinedUsers from '../../assets/images/new-design/joined-user.svg';
import MintdLock from '../../assets/images/new-design/lock.svg';
import DesktopCard from '../../assets/images/new-design/card-desktop.svg';
import MobileCard from '../../assets/images/new-design/card-mobile.svg';
import devconfig from '../../config/config';
import GetURLVar from '../../services/getUrl';
import APIPost from '../../services/post';

const Returns = (props) => {
    const [filter, setFilter] = useState('1Y');
    const [widgetOpen, setWidgetOpen] = useState(true);
    const [loader, setLoader] = useState(true);
    const [returnsData, setReturnsData] = useState(null);
    const [registered, setRegistered] = useState(false);
    const [currentYearLargeValue, setCurrentYearLargeValue] = useState(null);

    useEffect(() => {
        getUrl()
    }, [])

    const getUrl = async () => {
        var query = await GetURLVar();
        getReturns(query.report)
    }

    useEffect(() => {
        if (localStorage.getItem('registered') == true) {
            setRegistered(true)
        }
    }, [])

    const getReturns = (report = null) => {
        setLoader(true)
        var apiUrl = devconfig.url + '/get-returns'

        var data = JSON.stringify({
            reports: report
        })

        APIPost(apiUrl, data).then((response) => {
            if (response.status_code === 200) {
                let data = response.data;
                data['nifty'] = {
                    1: { returns_percentage: 1.2 },
                    3: { returns_percentage: 62.00 },
                    5: { returns_percentage: 76.00 },
                }
                let tmp = [];
                tmp.push({ name: 'mintd', amount: response.data['mintd'][1].returns_percentage });
                tmp.push({ name: 'zerodha', amount: response.data['zerodha'][1].returns_percentage });
                tmp.push({ name: 'nifty', amount: 1.2 })
                let large = Math.max(...tmp.map(o => o.amount));
                let amount = large * response.data['zerodha'][1].invested_amount;
                console.log(amount)
                setCurrentYearLargeValue(large);
                setLoader(false)
                setReturnsData(data)
                // setPieChartData(tempData)
                // setPieChartLabel(tempLabel)
            } else {
                setLoader(false)
                setReturnsData({})
            }
        });
    }

    useEffect(() => {
        if (returnsData) {
            let tmp = [];
            tmp.push({ name: 'mintd', amount: returnsData['mintd'][parseInt(filter.charAt(0))]?.returns_percentage });
            tmp.push({ name: 'zerodha', amount: returnsData['zerodha'][parseInt(filter.charAt(0))]?.returns_percentage });
            tmp.push({ name: 'nifty', amount: returnsData['nifty'][parseInt(filter.charAt(0))]?.returns_percentage });
            let large = Math.max(...tmp.map(o => o.amount));
            console.log('tmp,', tmp);
            console.log('larget', large)
            setCurrentYearLargeValue(large);
        }
    }, [filter, returnsData])



    // return chart RETURNS chart label value
    const _getReturns = (percentage, base) => {
        // let  base  = 1000000;

        let year = parseInt(parseInt(filter.charAt(0)));
        let added = (base / 100) * percentage;
        let total = added * year;
        var val = total;

        if (val >= 10000000) {
            val = (val / 10000000).toFixed(2) + ' Cr';
        } else if (val >= 100000) {
            val = (val / 100000).toFixed(2) + ' Lac';
        } else if (val < 100000) {
            val = val.toFixed(2)
        }
        return val
    }

    const _getReturnAmount = (z_return, m_return, base) => {
        let z_amount;
        let m_amount;
        let year = parseInt(parseInt(filter.charAt(0)));
        let added = (base / 100) * z_return;
        z_amount = added * year;
        let mintd_amount = (base / 100) * m_return;
        m_amount = mintd_amount * year;
        return (m_amount - z_amount).toFixed(2);
    }

    const getHeight = (percentage, base) => {
        let total;
        if (percentage == currentYearLargeValue) {
            total = 140;
        }
        else {

            let year = parseInt(parseInt(filter.charAt(0)));
            let val = (base / 100) * percentage;
            total = percentage / currentYearLargeValue * 100;
        }
        console.log('total', total)
        return total;
    }

    return (
        <div className="container e-returns-container">
            <div className="row">
                <div className="col-12 text-center mt-5">
                    <img src={Logo} className="e-logo" />
                </div>

                {/* Graph card */}
                <div className='row position-relative'>
                {
                                    !registered && !sessionStorage.getItem("registered") &&
                                    (
                                        <div className='e-blured-card text-center pt-5'>

                                        <img src={MintdLock} className="e-mintd-lock"/>
                                        <p className='mt-3'>
                                            Click here to see how you fare <br/> against traders across country (and US)
                                        </p>  
                                        <PrimaryBtn className='mt-3' toggle="modal" target="#register"
                                            name="Click to see"
                                        />
                                    </div>
                                    )
                                }
                <div className="col-lg-6 col-md-8 col-sm-12  offset-lg-3 offset-md-2 mt-5">
                    <div className='e-card px-5 py-4'>
                        <div className='row'>
                            <div className='col-12 mb-4 mt-2'>
                                <h5 className='e-card-title'>
                                    Oops! Didn’t beat 95% of the traders, Nifty and Mintd*
                                </h5>
                            </div>
                            {
                                !loader ?
                                    <div className='e-bar-wrapper'>
                                        <div className='row  e-graph-bottom'>
                                            <div className='col-4 text-center position-relative'>
                                                <h6 // graph label
                                                    style={{
                                                        color: getHeight(
                                                            returnsData['nifty'][parseInt(filter.charAt(0))]?.returns_percentage,
                                                            returnsData['zerodha'][parseInt(filter.charAt(0))]?.invested_amount
                                                        ) < 0 ? 'red' : null,
                                                        bottom: returnsData['nifty'][parseInt(filter.charAt(0))]?.returns_percentage ?
                                                            getHeight(
                                                                returnsData['nifty'][parseInt(filter.charAt(0))]?.returns_percentage,
                                                                returnsData['zerodha'][parseInt(filter.charAt(0))]?.invested_amount
                                                            ) > 0 ?
                                                                getHeight(
                                                                    returnsData['nifty'][parseInt(filter.charAt(0))]?.returns_percentage,
                                                                    returnsData['zerodha'][parseInt(filter.charAt(0))]?.invested_amount
                                                                ) + 'px' : 0
                                                            : 0// setting chart label position
                                                    }}
                                                >
                                                    {
                                                        returnsData['zerodha'][parseInt(filter.charAt(0))]?.invested_amount &&
                                                            returnsData['nifty'][parseInt(filter.charAt(0))]?.returns_percentage
                                                            ?

                                                            "₹" + _getReturns(
                                                                returnsData['zerodha'][parseInt(filter.charAt(0))]?.invested_amount,
                                                                returnsData['nifty'][parseInt(filter.charAt(0))]?.returns_percentage
                                                            )
                                                            :
                                                            null
                                                    }
                                                </h6>
                                                <div  // graph bar
                                                    className='e-nifty-bar'
                                                    style={{
                                                        height: returnsData['nifty'][parseInt(filter.charAt(0))]?.returns_percentage ?
                                                            getHeight(
                                                                returnsData['nifty'][parseInt(filter.charAt(0))]?.returns_percentage,
                                                                returnsData['zerodha'][parseInt(filter.charAt(0))]?.invested_amount
                                                            ) + 'px'
                                                            : 0,
                                                        bottom: getHeight(
                                                            returnsData['nifty'][parseInt(filter.charAt(0))]?.returns_percentage,
                                                            returnsData['zerodha'][parseInt(filter.charAt(0))]?.invested_amount
                                                        ) < 0 ?
                                                            getHeight(
                                                                returnsData['nifty'][parseInt(filter.charAt(0))]?.returns_percentage,
                                                                returnsData['zerodha'][parseInt(filter.charAt(0))]?.invested_amount
                                                            ) : 0
                                                    }
                                                    }>
                                                </div>
                                            </div>
                                            <div className='col-4 text-center position-relative'>
                                                <h6 // graph label
                                                    style={{
                                                        color: getHeight(
                                                            returnsData['zerodha'][parseInt(filter.charAt(0))]?.returns_percentage,
                                                            returnsData['zerodha'][parseInt(filter.charAt(0))]?.invested_amount
                                                        ) < 0 ? 'red' : null,
                                                        bottom: returnsData['zerodha'][parseInt(filter.charAt(0))]?.returns_percentage ?
                                                            getHeight(
                                                                returnsData['zerodha'][parseInt(filter.charAt(0))]?.returns_percentage,
                                                                returnsData['zerodha'][parseInt(filter.charAt(0))]?.invested_amount
                                                            ) > 0 ?
                                                                getHeight(
                                                                    returnsData['zerodha'][parseInt(filter.charAt(0))]?.returns_percentage,
                                                                    returnsData['zerodha'][parseInt(filter.charAt(0))]?.invested_amount
                                                                ) + 'px' : 0
                                                            : 0// setting chart label position
                                                    }}
                                                >
                                                    {
                                                        returnsData['zerodha'][parseInt(filter.charAt(0))]?.invested_amount &&
                                                            returnsData['zerodha'][parseInt(filter.charAt(0))]?.returns_percentage
                                                            ?
                                                            "₹" + _getReturns(
                                                                returnsData['zerodha'][parseInt(filter.charAt(0))]?.invested_amount,
                                                                returnsData['zerodha'][parseInt(filter.charAt(0))]?.returns_percentage
                                                            )
                                                            :
                                                            null
                                                    }
                                                </h6>
                                                <div  // graph bar
                                                    className='e-zerodha-bar'
                                                    style={{
                                                        height: returnsData['zerodha'][parseInt(filter.charAt(0))]?.returns_percentage ?
                                                            getHeight(
                                                                returnsData['zerodha'][parseInt(filter.charAt(0))]?.returns_percentage,
                                                                returnsData['zerodha'][parseInt(filter.charAt(0))]?.invested_amount
                                                            ) + 'px'
                                                            : 0
                                                    }
                                                    }>
                                                </div>
                                            </div>
                                            <div className='col-4 text-center position-relative'>
                                                <h6 // graph label
                                                    style={{
                                                        color: getHeight(
                                                            returnsData['mintd'][parseInt(filter.charAt(0))]?.returns_percentage,
                                                            returnsData['zerodha'][parseInt(filter.charAt(0))]?.invested_amount
                                                        ) < 0 ? 'red' : null,
                                                        bottom: returnsData['mintd'][parseInt(filter.charAt(0))]?.returns_percentage ?
                                                            getHeight(
                                                                returnsData['mintd'][parseInt(filter.charAt(0))]?.returns_percentage,
                                                                returnsData['zerodha'][parseInt(filter.charAt(0))]?.invested_amount
                                                            ) > 0 ?
                                                                getHeight(
                                                                    returnsData['mintd'][parseInt(filter.charAt(0))]?.returns_percentage,
                                                                    returnsData['zerodha'][parseInt(filter.charAt(0))]?.invested_amount
                                                                ) + 'px'
                                                                : '0'
                                                            :
                                                            0 // setting chart label position
                                                    }}
                                                >
                                                    {
                                                        returnsData['zerodha'][parseInt(filter.charAt(0))]?.invested_amount &&
                                                            returnsData['mintd'][parseInt(filter.charAt(0))]?.returns_percentage
                                                            ?

                                                            "₹" + _getReturns(
                                                                returnsData['zerodha'][parseInt(filter.charAt(0))]?.invested_amount,
                                                                returnsData['mintd'][parseInt(filter.charAt(0))]?.returns_percentage
                                                            )
                                                            :
                                                            null
                                                    }
                                                </h6>
                                                <div  // graph bar
                                                    className='e-mintd-bar'
                                                    style={{
                                                        height: returnsData['mintd'][parseInt(filter.charAt(0))]?.returns_percentage ?
                                                            getHeight(
                                                                returnsData['mintd'][parseInt(filter.charAt(0))]?.returns_percentage,
                                                                returnsData['zerodha'][parseInt(filter.charAt(0))]?.invested_amount
                                                            ) + 'px'
                                                            : 0//setting height of bar  
                                                    }
                                                    }>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-4 text-center'>
                                                <img src={Nifty50} className="e-graph-legend" />
                                            </div>
                                            <div className='col-4 text-center'>
                                                <h5 className='mt-2 e-graph-label'>Your returns</h5>
                                            </div>
                                            <div className='col-4 text-center'>
                                                <img src={MintdLeg} className="e-graph-legend" />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                            <div className='col-12 text-center mt-5'>
                                <span
                                    disabled={returnsData && returnsData['zerodha'][1]?.invested_amount == null ? true : false}
                                    className={filter === '1Y' ? 'e-switch e-active-switch' : 'e-switch '}
                                    onClick={() => { if (returnsData['zerodha'][1]?.invested_amount !== null) setFilter('1Y') }}
                                >
                                    1Y
                                </span>
                                <span
                                    disabled={returnsData && returnsData['zerodha'][3]?.invested_amount == null ? true : false}
                                    className={filter === '3Y' ? 'e-switch e-active-switch  mx-2' : 'e-switch mx-2'}
                                    onClick={() => { if (returnsData['zerodha'][3]?.invested_amount != null) setFilter('3Y') }}
                                >
                                    3Y
                                </span>
                                <span
                                    disabled={returnsData && returnsData['zerodha'][5]?.invested_amount == null ? true : false}
                                    className={filter === '5Y' ? 'e-switch e-active-switch' : 'e-switch '}
                                    onClick={() => { if (returnsData['zerodha'][5]?.invested_amount != null) setFilter('5Y') }}
                                >
                                    5Y
                                </span>
                            </div>
                            <div className='col-12 text-center mt-4 pt-3'>
                                <p className='e-note'>
                                    *We used Mintd risk score 10 to analyze possible returns
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Earnings */}
                <>
                    {
                        returnsData &&
                            returnsData['mintd'][parseInt(filter.charAt(0))]?.returns_percentage &&
                            returnsData['zerodha'][parseInt(filter.charAt(0))]?.returns_percentage &&
                            returnsData['zerodha'][parseInt(filter.charAt(0))]?.invested_amount ?
                            <>
                                {
                                    _getReturnAmount(
                                        returnsData['zerodha'][parseInt(filter.charAt(0))]?.returns_percentage,
                                        returnsData['mintd'][parseInt(filter.charAt(0))]?.returns_percentage,
                                        returnsData['zerodha'][parseInt(filter.charAt(0))]?.invested_amount

                                    ) > 0 ?
                                        <div className="col-lg-6 col-md-8 col-sm-12 offset-lg-3 offset-md-2 mt-3">
                                            <div className='e-card  py-3 text-center   e-margin-left'>

                                                <h5 className='e-earnings'>
                                                    You would have gained extra

                                                    <span className='mx-2'>
                                                        ₹{
                                                            _getReturnAmount(
                                                                returnsData['zerodha'][parseInt(filter.charAt(0))]?.returns_percentage,
                                                                returnsData['mintd'][parseInt(filter.charAt(0))]?.returns_percentage,
                                                                returnsData['zerodha'][parseInt(filter.charAt(0))]?.invested_amount

                                                            )
                                                        }
                                                    </span>
                                                    with Mintd
                                                </h5>

                                            </div>
                                        </div>
                                        :
                                        null
                                }
                            </>
                            :
                            null
                    }
                </>
                </div>


                {/* Warning */}
                <div className='col-lg-6 col-md-8 col-sm-12 offset-lg-3 offset-md-2 mt-5 text-center'>
                    <img src={Alert} />
                    <h5 className='e-warning mt-2'>
                        “Donʼt turn your cash to ash.<br className='e-br-tag' />
                        Get some rest while we mint some money for you?
                    </h5>
                </div>

                {/* sign up strip */}
                <div className='col-lg-9 col-md-8 col-sm-12 offset-lg-1 offset-md-2  my-5'>
                    <div className='e-primary-strip py-3 text-center offset-lg-1' onClick={() => window.open('https://www.mintd.in/')}>
                        Beat the market with Mintd
                        <span className='ms-3'>Sign up now</span>
                        <img src={Arrow} className="ms-3" />
                    </div>
                </div>

                {/* Methodology */}
                <div className='col-12 text-center  my-5'>
                    <span className='e-methodology px-2 py-1'>METHODOLOGY</span>
                    <h4 className='e-how-to-invest mt-2'>
                        How we invest your money
                    </h4>
                    <p className='e-answer px-5 mt-2'>
                        Lorem ipsum dolor sit amet, conctetur ascing elit ut aliquam, purus sit amet luctus ,<br /> venenatis,
                        lectus magna fringilla urna, porttitor rhoncus
                    </p>
                </div>


                <div className='col-lg-10 col-md-12 col-sm-12 offset-lg-1 '>
                    <div className='row e-detail-image-ul ms-lg-4'>
                        <img src={DesktopCard}
                            className="e-card-desktop"
                        />
                        <img src={MobileCard}
                            className="e-mobile-card"
                        />
                    </div>
                </div>

                <div className='col-lg-10 col-md-12 col-sm-12 offset-lg-1 mt-5'>
                    <div className='ms-lg-4'>
                        <h4 className='e-methodology-title mb-4 ms-1'>
                            How did you pick which asset classes to invest in?
                        </h4>
                        <p className='e-methodology-content ms-1'>
                            <ul>
                                <li>
                                    <b>Equities:</b> These represent pieces of a company, also called stocks.
                                </li>
                                <li>
                                    <b>Bonds:</b> A bond is a loan to another entity (like a company or a government). In return for that loan, the entity pays you a fixed amount of money a year, called a ‘coupon.’ Because you get a fixed return for loaning out that money, bonds are also called ‘fixed income’ securities. A bond is a type of debt instrument - the borrower is indebted to the lender.
                                </li>
                                <li>
                                    <b>Real estate:</b> This includes land, apartments, houses, offices, and buildings.
                                </li>
                                <li>
                                    <b>Commodities:</b> These are raw materials like gold, silver, copper, iron ore, and oil.
                                </li>
                            </ul>
                        </p>

                        <p className='e-methodology-content mt-4 ms-1'>
                            There are a couple of broader things to keep in mind when picking asset classes:
                        </p>
                        <p className='e-methodology-content mt-4 ms-1'>
                            <ul>
                                <li>
                                    There is a risk vs. return trade-off to consider when picking assets.
                                    In general, when you take a greater risk, you are compensated with a higher return for taking that risk.
                                </li>
                                <li>
                                    The correlation between assets is an important consideration.
                                    Correlation is an indication of how closely two or more things move together.
                                    Ideally, you want an uncorrelated portfolio of assets - i.e.,
                                    a collection of things that won’t all move in the same direction at the same time.
                                </li>
                            </ul>
                        </p>

                        <p className='e-methodology-content mt-4 ms-1'>
                            Keeping all these things in mind, we selected the assets classes mentioned below.
                        </p>
                    </div>
                </div>

                <div className='col-12 text-center mt-5'>
                    <img src={LineGraph} className="e-graph-image" />
                </div>

                <div className='col-12 text-center mt-5'>
                    <h6 className='e-graph-title mb-5'>Risk/Return Tradeoff</h6>
                    <img src={CurvedGraph} className="mt-5 e-graph-image" />
                    <h6 className='e-graph-title mt-5 mb-5'>Asset Classes- Risk/Return Tradeoff</h6>
                </div>

            </div>

            <div className='col-10 offset-1 mt-5'>
                <p className='e-methodology-link ms-4 mb-5'>
                    Read our Methodology on
                    <a href="https://www.mintd.in/methodology" target="_blank" className='ms-2'>mintd.in/methodology</a>
                </p>
            </div>

            <div className='col-12 mb-5 mt-5'>
                <div className='e-returns-page-card px-5 pt-5'>
                    <div className='row'>
                        <div className='col-12'>
                            <h6 className='e-mintd-card-sub-title'>
                                Set your wealth creation on auto pilot with us
                            </h6>
                        </div>
                        <div className='col-lg-6 col-md-12 col-sm-12 col-12 mt-3 '>
                            <h4 className='e-title-mintd-card pe-5'>
                                Setup your professionally managed wealth portfolio in 5 minutes
                            </h4>
                            <img src={JoinedUsers} className="mt-2 mb-3" />
                            <div className='e-mintd-banner-btn-wrapper mb-md-3'>
                                <SecondaryBtn
                                    className='e-dark-s-btn mt-2'
                                    name="Sign Up"
                                    handleClick={() => window.open('https://app.mintd.in/', '_blank')}
                                />
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12 col-sm-12 col-12 mt-3 mt-lg-0 mt-md-3  position-relative'>
                            <img src={require('../../assets/images/MintdHome.png')} className="e-mintd-dashboard" />
                        </div>
                    </div>
                </div>
            </div>
            <Register
                success={() => {
                    setRegistered(true)
                }} />
        </div>
    )
}

export default Returns;