//import package
import { useState, useEffect } from 'react';

// import assets
import WallStreet from '../../assets/video/wall-street.mp4';
import Logo from '../../assets/images/new-design/logo.svg';
import Upload from '../../assets/images/new-design/upload.svg';
import Broker from '../../assets/images/new-design/broker.svg';
import Upstocks from '../../assets/images/new-design/upstocks.svg';
import Grow from '../../assets/images/new-design/grow.svg';
import Angel from '../../assets/images/new-design/angel.svg';
import Sticker from '../../assets/images/new-design/sticker.svg';

import { PortfolioReturnModal } from '../../components/modal/index';


const Home = (props) => {
    const [type, setType] = useState(null);

    useEffect(()=> {
        console.log('inside');
    },[])

    return(
        <div className="e-home-container ">
            <div className="e-video-wrapper">
            <video  
                className='e-player'
                id="e-vid" 
                autoPlay 
                muted
                loop
            >
                <source src={WallStreet} type="video/mp4"/> 
            </video>
            </div>
            <div className='row e-page-content mt-5'>
                <div className='col-12 text-center'>
                    <img src={Logo} className="e-logo"/>
                </div>
                <div className='col-lg-6 col-md-12 col-sm-12 offset-lg-3  text-center mt-5 pt-3'>
                    <h2 className='e-title'>
                        Is your portfolio
                        Wall Street worthy?
                    </h2>
                    <p className='e-description'>
                        Ours is! If you beat us - Goodies are on the way.
                    </p>
                    <button className='e-upload-btn mt-5'
                        data-bs-toggle="modal" data-bs-target="#portfolioReturn"
                    >
                        <img src={Upload} className="me-3"/>
                        Upload
                    </button>
                    <span className='mt-2'>Click to upload your broker’s tradebook</span>
                    
                    <ul className='ps-0'>
                        <li className='me-3'><img src={Broker}/></li>
                        <li className='me-3'><img src={Upstocks}/></li>
                        <li className='me-4'><img src={Grow}/></li>
                        <li><img src={Angel}/></li>
                    </ul>
                    <span className='e-more pb-5'>+ more coming soon</span>
                </div>
                <div className='col-12'>
                    <img src={Sticker} className="e-sticker"/>
                </div>
            </div>
            <PortfolioReturnModal type={"ZERODHA"} />
        </div>
    )
}

export default Home;