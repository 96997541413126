/*import package*/
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";

/*import custom component*/

import Index from './container/home/index.js';
// import Returns from './container/returns/index.js'

import NewHome from './container/home/newHome.js';
import NewReturns from './container/returns/newReturns';

import Home from './container/home/home';
import Returns from './container/returns/returns.js';

export default function App(props) {

  useEffect(()=>{
    // console.log = () => {}
		// console.warn = () => {}
		// console.error = () => {}
		// console.clear()
  },[])
  return (
    <>


      <Router>
        <div className='container-fluid gx-0'>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/returns" element={<Returns />} />
            {/* <Route exact path="/login" element={<Login />} /> */}
          </Routes>
        </div>
      </Router>

    </>
  )
}

