/*
 *   File : register.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Success Modal
 *   Integrations : 07-09-2022
 *   Version : v1.0
 */

/*import packages*/
import React, { useState, useEffect } from 'react';
import $ from 'jquery';


/*import componets*/
import { Input, PhoneInput, } from '../input/index';
import { SecondaryBtn, PrimaryBtn, } from '../cta/index';
import { Toast } from '../note';


/*import styles*/
import styles from '../../styles/components/edit.module.scss';

/* import assests */
import Envelope from '../../assets/images/modal/envelope.svg';

// import Service
import devconfig from '../../config/config';
import APIPost from '../../services/post';


export default function Register(props) {

    const [apiLoader, setAPILoader] = useState(false);
    const [error, setError] = useState(null);
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState(null);
    const [registered, setRegistered] = useState(false)
    const [name, setName] = useState(null);

    useEffect(() => {
        let modal = document.getElementById('register');
        modal.addEventListener('hidden.bs.modal', () => {
            setPhone(null);
            setEmail("");
            setRegistered(false)
        })
    }, [])

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 3000)
        }
    }, [error])


    const continueNext = () => {
        
    }

    const createAccount = () => {
        setAPILoader(true)
        var url = devconfig.url + '/subscribe';

        var data = JSON.stringify({
            email: email,
            phone: phone
        })

        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                // setRegistered(true);
                setAPILoader(false);
                $("#close").attr("data-bs-dismiss", "modal");
                $("#close").trigger("click");
                props.success()
                sessionStorage.setItem("registered", true)
            } else {
                setAPILoader(false);
                setError(response.message);
            }
        })
    }

    return (
        <>
            <div className={`${styles.e_edit_modal} modal fade e-edit-modal`} id="register" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered ">
                    {
                        !registered ?
                            <div className={`modal-content p-3 ${styles.e_modal_content}`}>
                                <div className={`${styles.header} modal-header`}>
                                    <div className='row'>
                                        <div className='col-lg-10'>
                                            <h6>Enter your details below and a detailed analysis is on the way to your inbox</h6>
                                        </div>
                                    </div>
                                    {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                                </div>
                                <div className="container px-3">
                                    <div className="row mt-3">

                                        <div className='col-lg-12 mb-3'>
                                            <PhoneInput
                                                label="Phone number"
                                                className="mb-3"
                                                value={phone}
                                                valueChange={(value) => { setPhone(value) }}
                                                type="number" />

                                            {/* <Input
                                                label="Name"
                                                value={name}
                                                valueChange={(value) => {
                                                    setName(value);
                                                }}
                                                type="text" 
                                            /> */}

                                        </div>
                                        <div className='col-lg-12'>
                                            <Input
                                                label="email"
                                                value={email}
                                                valueChange={(value) => {
                                                    setEmail(value);
                                                }}
                                                type="email" />
                                        </div>

                                    </div>
                                    <div className="row pt-4 mt-2 pb-2">
                                        <span style={{ color: 'red' }}>{error}</span>
                                        <div className="col-lg-12 d-flex justify-content-end">
                                            <SecondaryBtn cancel="modal" name="Cancel" />
                                            {
                                                phone &&
                                                    email
                                                    ?
                                                    <PrimaryBtn
                                                        id="close"
                                                        name={apiLoader ? "Submit" : " Submit"}
                                                        className={apiLoader ? `e-btn-loader ms-2` : `ms-2`}
                                                        handleClick={() => createAccount()} />
                                                    :
                                                    <PrimaryBtn
                                                        name="Submit"
                                                        disabled={true}
                                                        className={`e-disabled ms-2`}
                                                    />
                                            }


                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className={`${styles.modal_success} modal-content py-5`}>

                                <div className="container px-3">
                                    <div className="row mt-3 text-center">
                                        <div className='col-lg-12'>
                                            <img src={Envelope} alt="" />
                                        </div>
                                        <div className='col-lg-12 py-4 '>
                                            <p className='px-4'>A detailed analysis of your portfolio has been sent to your email at <span>{email}</span></p>
                                        </div>
                                        <div className='col-lg-12 pb-3'>
                                            <PrimaryBtn
                                                id="close"
                                                name="Continue"
                                                handleClick={() => continueNext()} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </div>
            {
                error !== null ?
                    <Toast type={error} data={error} />
                    :
                    null
            }
        </>
    )
}
