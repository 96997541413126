/*
 *   File : config.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Config file for the template
 *   Version : 1.0.0
 *   Created : 10-11-2021
 */

/*----Meta -----*/
const Metadata = { title: 'Mintd-mw' }

// const url = process.env.REACT_APP_API_URL
// const url = "https://api.mintd.in/api/v1/customer"
const url = "https://api-dev.mintd.in/api/v1/customer/mw"

// const testUrl = "https://135xnkix7b.execute-api.ap-south-1.amazonaws.com/dev/api/v1/customer"
// testing link
//const url = "https://4ro2sz8vxf.execute-api.ap-south-1.amazonaws.com/internal/api/v1/customer"

var devconfig = {
    url: url
}


export default devconfig;
