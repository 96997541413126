/*
 *   File : portfolio-return.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Returns modal
 *   Integrations :
 *   Version : v1.0
 *   Created : 29-08-2022
 */

/*import packages*/
import React, { useEffect, useState, useRef } from 'react';
import $ from 'jquery';
import Papa from "papaparse";
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';


/*import componets*/
import { ComparisonCard } from '../../components/card/index';
import { MilestoneSlider } from '../../components/formElement/index';
import { Accordion } from '../../components/dataFormatting/index';
import { FileUpload } from '../../components/input/index';
import { PrimaryBtn, SecondaryBtn, SecondaryIconBtn } from '../../components/cta/index';
import { Toast } from '../../components/note/index';

/*import styles*/
import styles from '../../styles/components/portfolio-return.module.scss';

/* import assets */
// import Upload from '../../assets/images/input/upload.svg';
import File from '../../assets/images/new-design/File.svg';
import Csv from '../../assets/images/input/csv.svg';
import Delete from '../../assets/images/new-design/x-circle.svg';
import Arrow from '../../assets/images/cta/arrow.svg';
// import Merge from '../../assets/images/modal/mygif.gif';
import CalculatorLoader from '../../assets/images/new-design/calculator-loader.gif';
import UploadBtn from '../../assets/images/cta/uploadBtn.svg';
import ModalTitle from '../../assets/images/ModalTitle.svg';
import Upload from '../../assets/images/new-design/upload.svg';
import IndexUp from '../../assets/images/new-design/trending-up.svg';
import TradeBook from '../../assets/images/new-design/file-text.svg';
import DB from '../../assets/images/new-design/database.svg';

import IndexUpActive from '../../assets/images/new-design/trending-up-active.svg';
import TradeBookInactive from '../../assets/images/new-design/file-text-inactive.svg';
import DBActive from '../../assets/images/new-design/database-active.svg';

import UploadImageFileAWS from '../input/awsHelper';
import devconfig from '../../config/config';
import APIPost from '../../services/post';
import Icon from '../icons/icon';


const options = [
    { value: 1, label: "Zerodha" },
    // { value: 2, label: "Upstox" },
    // { value: 3, label: "Groww" },
    // { value: 4, label: "Angel Broking" },
    // { value: 3, label: "HDFC Securities" },
]



export default function PortfolioReturnModal(props) {
    const navigate = useNavigate()
    const [uploadFiles, setUploadFiles] = useState([]);
    const [step, setStep] = useState(1);
    const [error, setError] = useState();
    const [parsedData, setParsedData] = useState([]);
    //State to store table Column name
    const [tableRows, setTableRows] = useState([]);
    //State to store the values
    const [values, setValues] = useState([]);
    const [tables, setTables] = useState([]);
    const [reports, setReports] = useState([]);
    const [loader, setLoader] = useState(false);
    const [url, setUrl] = useState("");
    const [type, setType] = useState({ value: 1, label: "Zerodha" });
    const [loader_content, setLoaderContent] = useState([
        {
            text: 'Comparing it with index',
            icon : IndexUp,
            active : IndexUpActive
        },
        {
            text: 'Reading your tradebook',
            icon : TradeBookInactive,
            active : TradeBook
        },
        {
            text: 'Comparing it with Mintd returns',
            icon : DB,
            active : DBActive
        },
    ]);

    useEffect(() => {
        var myModalEl = document.getElementById('portfolioReturn')
        myModalEl.addEventListener('hidden.bs.modal', (event) => {
            setStep(1);
            setUploadFiles([]);
            setTables([]);
            setTableRows([]);
        })
    }, [])

    useEffect(() => {
        // if (step === 2 && url !== '') {
        //     setStep(step + 1)
        // }
    }, [step, url])



    /* drag and drop files */
    // useEffect(() => {
    //     $(document).on('dragdrop', '#e-upload', function () {
    //         // var regex = new RegExp("(.*?)\.(csv)$");
    //         let files = this[0].files
    //         console.log(this)
    //         for (let x = 0; x < files.length; x = x + 1) {
    //             if (files[x].type.split("/")[1] == 'csv') {
    //                 var filesCount = $(this)[0].files.length;
    //                 console.log("files", $(this)[0].files)
    //                 var textbox = $(this).prev();

    //                 if (filesCount === 1) {
    //                     var fileName = $(this).val().split('\\').pop();
    //                 } else {
    //                     textbox.text(filesCount + ' files selected');
    //                 }
    //                 changeHandler(this)
    //             } else {
    //                 setError("Not a csv file");
    //                 setTimeout(() => { setError(null) }, 3000)
    //             }
    //         }

    //     })
    // })


    /* csv file */
    const changeHandler = async (event) => {
        // Passing file data (event.target.files) to parse using Papa.parse

        let files = event.target.files
        console.log(files)
        for (let x = 0; x < files.length; x = x + 1) {
            // if (files[x].type.split("/")[1] == 'csv') {

                var file = event.target.files[x];
                
                var tempFileName = file.name;
                var fileReader = new FileReader();
                tempFileName = tempFileName.replaceAll(' ', '_');
                var tempKey = "files"
                tempKey = tempKey + "/" + new Date().getTime()
                var fileType = tempFileName.split("/").pop();
                var response = await UploadImageFileAWS(tempFileName, tempKey, file, fileType);

                if (response.status) {
                    let temp = reports
                    temp.push(response.cdn_url)
                    setReports(temp);

                    let tmp = [...uploadFiles]
                    Object.values(event.target.files).map((item, index) => {
                        return (
                            tmp.push({ name: item.name, size: item.size, id: index + 1 })
                        )
                    })
                    setUploadFiles(tmp);
                }
                else {
                    setError("Upload failed");
                    setTimeout(() => { setError(null) }, 3000)
                }


            // } else {
            //     setError("Not a csv file");
            //     setTimeout(() => { setError(null) }, 3000)
            // }
        }
    };

    /*------ get summarised report api -------*/

    const getSummarisedReport = () => {
        // setStep(step + 1)
        setLoader(true);
        var url = devconfig.url + "/get-summarized-report"

        var data = JSON.stringify({
            type: type.label,
            reports: reports
        });

        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setLoader(false);
                setUrl(response.data.report)
                Papa.parse(response.data.report, {
                    download: true,
                    complete: function (results) {
                        console.log(results)
                        const rowsArray = [];
                        const valuesArray = [];
                        // Iterating data to get column name and their values
                        results.data.map((d) => {
                            rowsArray.push(Object.keys(d));
                            valuesArray.push(Object.values(d));
                        });
                        let tmp = tables;
                        tmp.push({ titles: rowsArray[0], rows: valuesArray });
                        // console.log('tanle', tmp);


                        // Parsed Data Response in array format
                        setParsedData(results.data);

                        // Filtered Column Names
                        setTableRows(rowsArray[0]);

                        // Filtered Values
                        setValues(valuesArray);
                        setTables([...tmp])
                        setStep(step + 1)
                    },
                });
                // setStep(step + 1)
            } else {
                setLoader(false);
                setError(response.message);
                setTimeout(() => { setError(null) }, 3000)
            }
        })
    }

    /*------get returns ------*/
    const getReturns = () => {
        let element = document.getElementById('e-table'); // removing table
        element.classList.add('e-unmount-table');
        setTimeout(()=>{  // render loading screen
            setStep(3);
        },700)
        
        let timer = setInterval(()=>{ 
            let temp = loader_content;
            let shifted = temp.shift();
            temp.push(shifted);
            setLoaderContent([...temp]);
        },1000) // update index of loading screen content by 1

        setTimeout(() => {
            clearInterval(timer);
            $("#close").attr("data-bs-dismiss", "modal");
            $("#close").trigger("click")
            window.location.href = "/returns?report="+url;
        }, 4000)
    }

    return (
        <div className={`${styles.e_portfolio_return_modal} modal fade e-portfolio-modal `} id="portfolioReturn" tabIndex="-1" aria-labelledby="portfolioReturn" aria-hidden="true">
            <div
                className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered e-returns-modal `}
                style={{ maxWidth: step === 1 ? '655px' : step === 2 ? '855px' : 'max-content'}}
            >
                {
                    step !== 3 && (
                        <div className={`${styles.e_modal_content} modal-content`} id="e-table">
                            <div className={`${styles.e_modal_header} modal-header mb-0 pb-1`}>
                                <h3 className='pe-3'>Compare your portfolio returns with Mintd platform</h3>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                    onClick={() => setStep(1)}
                                ></button>
                            </div>

                            <div className="container px-0 pt-2 ">
                                {
                                    step === 1 && (
                                        <div className="row">
                                            <div className='col-5'>
                                                <label className='e-modal-label mb-2'>Choose your broker</label>
                                                <div className="e-select-wraper">
                                                    <Select id={props.id}
                                                        className={`${styles.e_select_box}` + " " + props.className}
                                                        onChange={(e) => { setType(e) }}
                                                        options={options}
                                                        value={type}
                                                        placeholder={'Select'}
                                                    // menuIsOpen={true/}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }


                                <>

                                    {/* -----------------First state--------------------- */}
                                    {
                                        step === 1 ?
                                            <>
                                                <div className="row mt-4">
                                                    <div className="col-lg-12">
                                                        <Accordion />
                                                    </div>
                                                </div>


                                                <>
                                                    <div className="row mt-4">
                                                        <div className="col-lg-6 col-md-6">
                                                            <div className="e-uploader" onDrop={(event) => changeHandler(event)}>
                                                                <div className='file-drop-area text-center' id="test">
                                                                    {/* <img src={Upload} alt="" className='text-center' style={{width: '50px'}} /> */}
                                                                    <p className='mb-2 pt-4 mt-2'>Drag and drop the files</p>
                                                                    <p>or</p>
                                                                    <PrimaryBtn
                                                                        className="e-modal-upload"
                                                                        name="Upload CSV files"
                                                                        image={Upload}
                                                                        handleClick={() => { $('#e-upload').click() }}
                                                                    />
                                                                </div>
                                                                <FileUpload
                                                                    multiple={true}
                                                                    name="file"
                                                                    id="e-upload"
                                                                    onChange={changeHandler}
                                                                    accept=".csv"
                                                                    className="file-input"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 mt-lg-0 mt-md-0 mt-sm-4 mt-4">
                                                            <div className='e-preview position-relative '>
                                                                <h6 className=''>Uploaded Files</h6>
                                                                {
                                                                    uploadFiles.length > 0 ?
                                                                        uploadFiles.map((item, index) => {
                                                                            return (
                                                                                <div className='row e-border-bottom'>
                                                                                    <div className='col-lg-2 col-md-2 col-sm-2 col-2 mb-2 mt-2'>
                                                                                        <img src={Csv} />
                                                                                    </div>
                                                                                    <div className='col-lg-8 col-md-8 col-sm-8 col-8 col-8 mb-2 mt-2'>
                                                                                        <p className='mb-0 e-file-p'>{item.name}</p>
                                                                                        <p className='mb-0 e-file-p'>
                                                                                            {item.size + " KB"}
                                                                                        </p>


                                                                                    </div>
                                                                                    <div className='col-lg-1 col-md-1 col-sm-1 col-1 text-end'>
                                                                                        <img src={Delete} className="mt-3" onClick={() => {
                                                                                            let tempArr = [...uploadFiles]
                                                                                            tempArr.splice(index, 1)
                                                                                            console.log(tempArr)
                                                                                            setUploadFiles(tempArr)
                                                                                            let temp = [...reports]
                                                                                            temp.splice(index, 1)
                                                                                            setReports(temp)
                                                                                        }} />
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })

                                                                        :
                                                                        <div className='text-center mt-4'>
                                                                            <img src={File} alt="" height="100%" />
                                                                            <p className='pt-4 mt-2'>No file has been uploaded</p>
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                </>
                                                <div className="row pt-4 mt-2">
                                                    <div className="col-lg-12 d-flex justify-content-end">
                                                        <SecondaryBtn name="Cancel" cancel="modal" className="me-4 e-modal-btn" />
                                                        {/* {
                          uploadFiles ? */}
                                                        <PrimaryBtn
                                                            className={loader ? "e-btn-loader e-modal-btn" : "e-modal-btn"}
                                                            name="Next" handleClick={() => { if (uploadFiles.length > 0) getSummarisedReport() }} />
                                                        {/* :
                            <PrimaryBtn name="Proceed" className="e-disable" />
                        } */}
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            step === 2 ?
                                                <>
                                                    <div className='e-border  mt-4 ' id="table-wrap">
                                                        <div className='row'>
                                                            <div className='col-lg-12 position-relative'>
                                                                <h6 className='p-3 mb-0'>Preview imported data</h6>

                                                                {/* Table */}
                                                                {
                                                                    tables.length &&
                                                                    tables.map((item, index) => {
                                                                        return (

                                                                            <table width="100%"  className='e-csv-table mb-3'>
                                                                                <tbody>
                                                                                    {item.rows.map((value, index) => {
                                                                                        console.log('row', value)
                                                                                        return (
                                                                                            <tr key={index}>
                                                                                                {
                                                                                                    value.map((val, i) => {
                                                                                                        return <td key={i}>{val}</td>;
                                                                                                    })}
                                                                                            </tr>
                                                                                        );
                                                                                    })}
                                                                                </tbody>
                                                                            </table>
                                                                        )
                                                                    })

                                                                }


                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="row pt-4 mt-2">
                                                        <div className="col-lg-12 d-flex justify-content-end">
                                                            <SecondaryBtn name="Cancel" cancel="modal" className="me-4 e-modal-btn" />
                                                            <PrimaryBtn
                                                                className={loader ? "e-btn-loader e-modal-btn" : "e-modal-btn"}
                                                                name="Compare your returns to Nifty and Mintd"
                                                                id="close"
                                                                handleClick={() => { getReturns() }} />
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                null


                                    }

                                </>
                            </div>
                        </div>
                    )
                }
                {
                    step === 3 && (
                        <div className='row mt-4'>
                            <div className='col-lg-12 '>
                                <div className='e-loader-wrapper pb-4'>
                                    <div className='col-12 text-center'>
                                        <img src={CalculatorLoader} className="myGif" />
                                    </div>
                                    <div className='mt-2'>
                                        {
                                            loader_content.map((item, key)=> {
                                                return(
                                                    <div className={ key ===1 ? `${styles.e_loader_content} mb-3 ps-5` : `mb-3 ps-5` }>
                                                        <img src={ key == 1 ? item.active : item.icon} className="me-2" />
                                                        <span style={{ color: key === 1 ? '#FFFFFF' : null }}>{item.text}</span>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    )
                }

            </div>
            {
                error ?
                    <Toast type={error} data={error} />
                    :
                    null
            }

        </div>
    )
}

